<template>
  <div
    :class="$style.root"
    :data-has-caption="includeCaption && activeSlideCaption ? '' : null"
    ref="root"
  >
    <CoreSwiperComponent
      @set-active-index="setActiveIndex"
      :isVisible="rootIsVisible"
    >
      <SwiperSlide
        v-for="(video, index) in videos"
        :key="video?.id || index"
      >
        <CoreMuxVideoComponent
          :video="video"
          :include-controls="includeControls"
        />
      </SwiperSlide>

      <SwiperSlide
        v-for="(image, index) in images"
        :key="image?.id || index"
      >
        <CoreMediaCarouselImageComponent :image="image" />
      </SwiperSlide>
    </CoreSwiperComponent>

    <CoreMediaCarouselCaptionComponent
      v-if="includeCaption && activeSlideCaption"
      :caption="activeSlideCaption"
    />
  </div>
</template>

<script setup>
import { SwiperSlide } from 'swiper/vue'

const root = ref(null)
const rootIsVisible = useElementVisibility(root)

const props = defineProps({
  images: {
    type: Array,
    required: false
  },
  videos: {
    type: Array,
    required: false
  },
  includeCaption: {
    type: Boolean,
    default: true
  },
  includeNav: {
    type: Boolean,
    default: true
  },
  includeControls: {
    type: Boolean,
    default: true
  }
})

const activeIndex = ref(0)
const emit = defineEmits(['setActiveIndex'])
const setActiveIndex = (index) => {
  activeIndex.value = index
  emit('setActiveIndex', index)
}

const activeSlideCaption = computed(() => {
  const videos = props?.videos || []
  const images = props?.images || []
  const slides = [...videos, ...images]?.filter(Boolean)
  const caption = slides ? slides[activeIndex?.value]?.attributes?.caption || slides[activeIndex?.value]?.caption : null
  if (!caption) { return null }
  const lastChar = caption?.trim()[caption?.length - 1]
  return lastChar?.match((/[a-zA-Z0-9]/))  !== null ? `${caption?.trim()}.` : caption?.trim()
})
</script>

<style module>
.root {
}

.root :global(.swiper) {
  position: relative;
  /* NOTE to compensate for miscalculations */
  clip-path: inset(0 1px 0 1px);
}

.root :global(.swiper-wrapper) {
  /* NOTE to overwrite height transition */
  transition-property: transform !important;
}
</style>
