<template>
  <transition :name="dir === 'next' ? 'button-right' : 'button-left' ">
    <button
      v-show="!disabled && show"
      :class="$style.root"
      :style="zIndexString"
      :dir="dir"
      :disabled="disabled"
      @click="dir === 'prev' ? swiper.slidePrev() : swiper.slideNext()"
    >
      <div
        :class="$style.button"
        data-transparent
      >
        <SvgoArrow :class="$style.svg" />
      </div>
    </button>
  </transition>
</template>

<script>
export default {
  props: {
    dir: {
      type: String
    },
    swiper: {
      type: [Function, Object],
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    disabled () {
      const isPrev = this.swiper.activeIndex === 0 && this.dir !== 'next'
      const isNext = this.swiper.activeIndex === this.swiper.slides.length - 1 && this.dir === 'next'
      return isPrev || isNext
    },
    zIndexString () {
      return `z-index: ${this.swiper.slides.length + 1}`
    }
  }
}
</script>

<style module>
.root {
  composes: reset-button from global;

  position: absolute;
  top: calc(50% - var(--size--button) / 2);
  padding: 0 var(--unit--horizontal);
}

.root[dir='prev'] {
  left: 0;
}

.root[dir='next'] {
  right: 0;
}

.button {
  composes: button from global;
}

.root[dir='next'] .button {
  transform: rotate(180deg)
}

.svg {
  composes: button-svg from global;
}

</style>
