<template>
  <div
    :class="$style.root"
    @keyup.left="slidePrev"
    @keyup.right="slideNext"
    tabindex="0"
    @click="root?.focus({ preventScroll: true })"
    ref="root"
  >
    <swiper
      :auto-height="true"
      :centered-slides="true"
      @swiper="onSwiper"
      :allowTouchMove="allowTouchMove"
      :speed="speed"
      @slideChange="handleOnSlideChange"
    >
      <template #container-start>
      </template>
      <slot />
    </swiper>

    <CoreSwiperPrevNextButtonComponent
      v-if="slidesCount > 0" dir="prev"
      :swiper="swiperInstance"
      :show="showButtonsOnlyWhenVisible ? isVisible : true"
    />

    <CoreSwiperPrevNextButtonComponent
      v-if="slidesCount > 0" dir="next"
      :swiper="swiperInstance"
      :show="showButtonsOnlyWhenVisible ? isVisible : true"
    />
  </div>
</template>

<script setup>
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/vue'

const root = ref(null)

const props = defineProps({
  speed: {
    type: Number,
    default: 400
  },
  allowTouchMove: {
    type: Boolean,
    default: true
  },
  includeNav: {
    type: Boolean,
    default: true
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  showButtonsOnlyWhenVisible: {
    type: Boolean,
    default: true
  },
})

const swiperInstance = ref(null)
const onSwiper = (swiper) => {
  swiperInstance.value = swiper
}

const slidesCount = computed(() => {
  return swiperInstance?.value?.slides?.length
})

onMounted(() => {
  if (this?.slidesCount === 1) {
    setTimeout(() => { swiperInstance?.value?.disable() }, 1500)
  }
})

onBeforeUnmount(() => {
  swiperInstance?.value?.destroy()
})

const emit = defineEmits(['setActiveIndex'])
const handleOnSlideChange = () => {
  emit('setActiveIndex', swiperInstance?.value?.activeIndex)
}

const slidePrev = () => {
  swiperInstance?.value?.slidePrev()
}

const slideNext = () => {
  swiperInstance?.value?.slideNext()
}
</script>

<style module>
.root {
  position: relative;
  z-index: 0;
}

.root:focus {
  outline: none;
}

.root :global(.swiper) {
  position: relative;
}

.root :global(.swiper-wrapper) {
  /* NOTE to overwrite height transition */
  transition-property: transform !important;
}
</style>
