<template>
  <div
    :class="$style.root"
    ref="root"
  >
    <BaseStrapiImageComponent
      :image="image?.attributes"
      :class="$style.image"
      :data-lightbox-image="canZoom(image?.attributes) ? '' : null"
      :data-upload-id="image?.id"
      :data-object-fit-contain="image?.attributes?.alternativeText?.toLowerCase() === 'contain' || image?.attributes?.objectFitContain === true ? '' : null"
    />

    <transition name="button-default">
      <button
        v-if="canZoom(image?.attributes) && rootIsVisible"
        :class="$style.button"
        @click="setQueryParams"
        data-transparent
      >
        <SvgoPlus :class="$style.svg" />
      </button>
    </transition>
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()

const root = ref(null)
const rootIsVisible = useElementVisibility(root)

const props = defineProps({
  image: {
    type: Object,
    required: true
  }
})

const canZoom = (image) => {
  return imageSizeCheck(image)
}

const setQueryParams = () => {
  router.push({ query: { ...route?.query, 'lightbox-image-id': props?.image?.id } })
}
</script>

<style module lang="scss">
.root {
  position: relative;
}

.root [data-orientation="landscape"] {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, 9 / 6);
  --base--image--object-fit: cover;
}

// NOTE temp
.root [data-object-fit-contain] [data-orientation="landscape"] {
  --base--image--object-fit: contain;
}

.root [data-orientation="square"] {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, 9 / 6);
  --base--image--object-fit: cover;
}

.root [data-orientation="portrait"] {
  --base--image--aspect-ratio: var(--core-image-carousel--aspect-ratio, 9 / 6);
  --base--image--object-fit: contain;
}

.button {
  composes: reset-button button from global;
  position: absolute;
  z-index: 1;

  top: var(--padding--element--top);
  right: var(--unit--horizontal);

  @media (max-width: $breakpoint-xs) {
    display: none;
  }
}

.svg {
  composes: button-svg from global;
}
</style>
